import React from "react"
import Layout from "../components/layout/Layout"

export default function PageNotFound() {
  return (
    <Layout>
      <h1>Not found</h1>
      <p>Sorry! This page seems not to exist.</p>
    </Layout>
  )
}
